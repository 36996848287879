import { createSlice } from '@reduxjs/toolkit';

import { packageApi } from './package';

type PackageState = {
	warnings: string[] | undefined;
};

const packageSlice = createSlice({
	name: 'package',
	initialState: { warnings: [] } as PackageState,
	reducers: {
		setWarnings: (state, action) => {
			state.warnings = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addMatcher(packageApi.endpoints.createPackage.matchFulfilled, (state, { payload }) => {
			state.warnings = payload.warnings;
		});
	},
});

export const { setWarnings } = packageSlice.actions;

export default packageSlice.reducer;
